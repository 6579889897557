<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        class="d-flex align-center justify-space-between"
      >
        <span class="titulo-pagina">
          {{ tituloFormulario }}
        </span>
      </v-col>
    </v-row>
    <div class="d-flex flex-column px-12 py-16">
      <template v-if="roteiro">
        <v-icon
          class="mx-auto mb-4"
          size="72"
          color="rgba(18, 183, 106, 1)"
        >
          $mdiCheckCircleOutline
        </v-icon>
        <p class="mx-auto text-center">
          A execução do roteiro foi finalizada com sucesso!
        </p>
      </template>
      <template v-else>
        <v-icon
          class="mx-auto mb-4"
          size="72"
          color="rgba(247, 144, 9, 1)"
        >
          $alertWarningtOutline
        </v-icon>
        <p class="mx-auto text-center">
          Não existe um roteiro para execução nesta data!
        </p>
      </template>
    </div>
    <botao-padrao
      outlined
      color="secondary"
      class="flex flex-grow-1 mt-4"
      btn100
      @click="voltar"
    >
      {{
        $t(
          'modulos.roteiro_execucao_envio_material.botoes.voltar_para_roteiro'
        )
      }}
    </botao-padrao>
  </div>
</template>
<script>
export default {
  beforeRouteEnter(to, from, next) {
    if (from.name === null) {
      next({ name: 'roteiro-executar-envio-material' });

      return;
    } else next();
  },
  computed: {
    tituloFormulario() {
      return this.$t(
        'modulos.roteiro_execucao_envio_material.titulos.roteiro',
        { data: new Date().toLocaleDateString() }
      );
    },
    roteiro() {
      const { name } = this.$route;
      if (name === 'roteiro-executar-envio-material-finalizado') {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.roteiro_execucao_envio_material.titulos.roteiro', {
        data: new Date().toLocaleDateString(),
      })
    );
  },
  methods: {
    voltar() {
      this.$router.push({
        name: 'roteiro-envio-material',        
      });
    }
  }
};
</script>
